import { useCallback, useEffect, useMemo } from 'react';

import cookie from 'cookie';
import { useDispatch, useSelector } from 'react-redux';
import { AuthService } from 'shared/auth/auth.service';
import {
    getAuthToken,
    getIsLoggedIn,
    getLoginResult,
    getRoles,
    setAuthToken,
    setImpersonation,
    setLoginResult as setLoginResultReducer,
} from 'state/auth/slice';
import { useAppSelector } from 'state/buildStore';
import { resetFacility } from 'state/facility/slice';
import { resetProperty } from 'state/property/slice';
import { parseJwt } from 'utils/parseJWT';

const useAuth = () => {
    const dispatch = useDispatch();

    const authService = useMemo(() => new AuthService(), []);

    const getAuthTokens = useCallback(() => {
        const cookies = cookie.parse(document.cookie);

        const accessToken = cookies.accessToken;
        const idToken = cookies.idToken;

        const idTokenPayload = parseJwt(idToken);

        dispatch(setAuthToken([accessToken, idTokenPayload, idToken]));
    }, [dispatch]);

    const login = useCallback(
        async (data) => {
            const result = await authService.login(data);
            dispatch(setLoginResultReducer(result));

            const session = await authService.isLoggedIn();

            if (session) {
                getAuthTokens();
            }
        },
        [authService, dispatch, getAuthTokens],
    );

    const logout = useCallback(async () => {
        await authService.logout();
        dispatch(setAuthToken(null));
        dispatch(setImpersonation(null));
    }, [authService, dispatch]);

    const setLoginResult = useCallback(
        (str) => dispatch(setLoginResultReducer(str)),
        [dispatch],
    );

    const roles = useSelector(getRoles);
    const authToken = useSelector(getAuthToken);
    const isLoggedIn = useSelector(getIsLoggedIn);
    const loginResult = useSelector(getLoginResult);
    const impersonation = useAppSelector((state) => state.auth.impersonation);

    useEffect(() => {
        const checkSession = async () => {
            if (isLoggedIn === null) {
                const session = await authService.isLoggedIn();
                if (session) {
                    getAuthTokens();
                } else {
                    dispatch(setAuthToken(null));
                    dispatch(resetFacility());
                    dispatch(resetProperty());
                }
            }
        };

        checkSession();
    }, [authService, dispatch, isLoggedIn, getAuthTokens]);

    return {
        actions: {
            login,
            logout,
            setLoginResult,
        },
        roles,
        authService,
        authToken,
        isLoggedIn,
        loginResult,
        impersonation,
    };
};

export default useAuth;
