import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface RegisterRequest {
    username: string;
    password: string;
}

interface ConfirmRegistrationRequest {
    username: string;
    code: string;
}

interface ResendCodeRequest {
    email: string;
}

interface PasswordRecoveryRequest {
    username: string;
}

interface ResetPasswordRequest {
    username: string;
    verificationCode: string;
    newPassword: string;
}

interface LoginRequest {
    username: string;
    password: string;
}

interface RefreshTokenRequest {
    refreshToken: string;
}

interface LogoutRequest {
    accessToken: string;
    refreshToken: string;
}

const serviceKey = window._env_.SERVICE_KEY;

export const api = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: window._env_.MS_SSO_BASE_URL,
    }),
    endpoints: (builder) => ({
        register: builder.mutation<any, RegisterRequest>({
            query: ({ username, password }) => ({
                url: `/api/user/${serviceKey}/create`,
                method: 'POST',
                body: {
                    username,
                    password,
                    userAttributes: [
                        { name: 'email', value: username },
                        { name: 'custom:isD2CUser', value: 'true' },
                    ],
                },
            }),
        }),
        confirmRegistration: builder.mutation<any, ConfirmRegistrationRequest>({
            query: ({ username, code }) => ({
                url: `/api/user/${serviceKey}/confirm`,
                method: 'POST',
                body: {
                    username,
                    code,
                },
            }),
        }),
        resendCode: builder.mutation<any, ResendCodeRequest>({
            query: ({ email }) => ({
                url: `/api/user/${serviceKey}/resend-confirmation-code`,
                method: 'POST',
                body: {
                    email,
                },
            }),
        }),

        requestPasswordRecovery: builder.mutation<any, PasswordRecoveryRequest>(
            {
                query: ({ username }) => ({
                    url: `/api/password/reset-password/${serviceKey}/request?username=${encodeURIComponent(
                        username,
                    )}`,
                    method: 'POST',
                }),
            },
        ),
        resetPassword: builder.mutation<any, ResetPasswordRequest>({
            query: ({ username, verificationCode, newPassword }) => ({
                url: `/api/password/reset-password/${serviceKey}/confirm`,
                method: 'POST',
                body: {
                    username,
                    verificationCode,
                    newPassword,
                },
            }),
        }),
        login: builder.mutation<any, LoginRequest>({
            query: ({ username, password }) => ({
                url: `/api/auth/token/${serviceKey}`,
                method: 'POST',
                body: {
                    username,
                    password,
                },
            }),
        }),
        refreshTokens: builder.mutation<any, RefreshTokenRequest>({
            query: ({ refreshToken }) => ({
                url: `/api/auth/refresh-token/${serviceKey}`,
                method: 'POST',
                body: {
                    refreshToken,
                },
            }),
        }),
        logout: builder.mutation<any, LogoutRequest>({
            query: ({ accessToken, refreshToken }) => ({
                url: `/api/auth/logout/${serviceKey}`,
                method: 'POST',
                body: {
                    accessToken,
                    refreshToken,
                },
            }),
        }),
    }),
});
