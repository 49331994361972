import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import mixpanel from 'mixpanel-browser';

import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
    // dsn: 'https://22ac9e9a81ca4d9b9a912506a1b67693@o1061223.ingest.sentry.io/6164631',
    dsn: window._env_.SENTRY_URL,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init(window._env_.MIXPANEL_ID, { debug: false });

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
