import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { api as AuthApi } from 'services/auth';
import { api as FacilityApi } from 'services/facility/facility';
import { api as ProfileApi } from 'services/profile/profile';
import { api as PropertyApi } from 'services/property';

import authReducer from './auth';
import facilityReducer from './facility';
import propertyReducer from './property';

export const createNewStore = (preloadedState?: any) => {
    const store = configureStore({
        reducer: {
            auth: authReducer,
            facility: facilityReducer,
            property: propertyReducer,
            [FacilityApi.reducerPath]: FacilityApi.reducer,
            [PropertyApi.reducerPath]: PropertyApi.reducer,
            [ProfileApi.reducerPath]: ProfileApi.reducer,
            [AuthApi.reducerPath]: AuthApi.reducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(
                FacilityApi.middleware,
                PropertyApi.middleware,
                ProfileApi.middleware,
                AuthApi.middleware,
            ),
        preloadedState,
    });

    return store;
};

const store = createNewStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
