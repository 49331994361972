import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Loading from 'components/loading/Loading';
import useAuth from 'helpers/useAuth';
import useQueryParams from 'helpers/useQueryParams';
import mixpanel from 'mixpanel-browser';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { LoginModel } from 'shared/auth/model/login.model';
import appRoutes from 'utils/routes';

import {
    InputsContainer,
    StyledAlert,
    StyledButton,
    StyledButtonAsLink,
    StyledForm,
    StyledLink,
} from '../../Authentication.styles';

import { schema } from './schema';

const EMAIL_QUERY = 'emailAddress';

const Login: FC = () => {
    const { t } = useTranslation('authentication');
    const history = useHistory();
    const { search } = useLocation();
    const query = useQueryParams();
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const { isLoggedIn } = useAuth();

    const emailAddress = query.get(EMAIL_QUERY) || '';

    const {
        register,
        control,
        handleSubmit,
        getValues,
        formState: { errors, isDirty, isValid },
    } = useForm<LoginModel>({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        mode: 'onChange',
        defaultValues: {
            email: emailAddress,
        },
    });
    const {
        actions: { login, setLoginResult },
        loginResult,
        authService,
    } = useAuth();

    const requestNewCode = useCallback(() => {
        const { email, password } = getValues();
        setIsLoading(true);
        authService.resendCode(email).then(() => {
            authService.registeredUser = btoa(
                JSON.stringify({
                    email,
                    password,
                }),
            );
            history.push(appRoutes.confirmRegistration.root + search);
        });
    }, [authService, getValues, history, search]);

    useEffect(() => {
        return () => {
            setLoginResult('');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            history.push({
                pathname: appRoutes.home.root,
            });
        }
    }, [isLoggedIn, history]);

    useEffect(() => {
        if (loginResult === 'success') {
            setIsLoading(false);
            mixPanelAnalytics();

            const queryParams = new URLSearchParams(search);

            if (queryParams.has(EMAIL_QUERY)) {
                queryParams.delete(EMAIL_QUERY);
            }

            history.push({
                pathname: appRoutes.home.root,
                search: '?' + queryParams.toString(),
            });
        } else if (loginResult === 'needsVerification') {
            requestNewCode();
        } else if (loginResult === '') {
            setHasError(false);
        } else {
            setIsLoading(false);
            setHasError(true);
        }
    }, [history, loginResult, requestNewCode, search]);

    const onSubmit = useCallback(
        (data: LoginModel) => {
            setIsLoading(true);
            setHasError(false);
            login(data);
        },
        [login],
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>): void => {
            // Ignore space key
            if (event.key === ' ') {
                event.preventDefault();
            }

            hasError && isValid && setLoginResult('');
        },
        [hasError, isValid, setLoginResult],
    );

    const mixPanelAnalytics = () => {
        mixpanel.identify(localStorage?.user_email);
        mixpanel.track('Login');
    };

    return (
        <div>
            {hasError && (
                <StyledAlert severity='error'>
                    {t('login.form.error')}
                    <br />
                    <br />
                    <span>{t('login.form.validate-start')}</span>
                    <StyledButtonAsLink onClick={requestNewCode}>
                        {t('login.form.validate-end')}
                    </StyledButtonAsLink>
                </StyledAlert>
            )}

            <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
                <InputsContainer>
                    <Controller
                        control={control}
                        name='email'
                        render={({
                            field: { onChange, onBlur, value, ref },
                        }) => (
                            <TextField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                inputRef={ref}
                                placeholder={t('login.form.email')}
                                type='email'
                                // variant="outlined"
                                error={!!errors.email}
                                helperText={
                                    errors?.email?.type === 'email' &&
                                    errors?.email?.message
                                }
                                fullWidth
                                onKeyDown={handleKeyDown}
                                disabled={!!emailAddress}
                            />
                        )}
                    />
                    <TextField
                        {...register('password')}
                        id='password'
                        placeholder={t('login.form.password')}
                        type='password'
                        error={!!errors.password}
                        fullWidth
                        onKeyDown={handleKeyDown}
                    />
                </InputsContainer>
                <StyledButton
                    variant='contained'
                    color='secondary'
                    type='submit'
                    fullWidth
                    disabled={isLoading || !isDirty || !isValid}
                >
                    {isLoading ? <Loading /> : t('login.form.sign-in')}
                </StyledButton>
                <StyledLink to={appRoutes.recover.root + search}>
                    {t('login.form.forgot-password')}
                </StyledLink>
            </StyledForm>
        </div>
    );
};

export default Login;
