import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import cookie from 'cookie';
import { api } from 'services/auth';
import store from 'state/buildStore';
import { parseJwt } from 'utils/parseJWT';

import { ConfirmRegistrationModel } from './model/confirm-registration.model';
import { LoginModel } from './model/login.model';
import { RecoverPasswordModel } from './model/recover-password.model';
import { RegisterModel } from './model/register.model';
import { ResetPasswordModel } from './model/reset-password.model';

export type LoginResult = 'success' | 'failed' | 'needsVerification' | '';

/**
 * Service to manage AWS Cognito authentication.
 */
export class AuthService {
    get registeredUser(): string | null {
        return sessionStorage.getItem('registered_user_info');
    }

    set registeredUser(value: string | null) {
        if (!value) {
            sessionStorage.removeItem('registered_user_info');
        } else {
            sessionStorage.setItem('registered_user_info', value);
        }
    }

    get marketingFlag(): string | null {
        return sessionStorage.getItem('marketing_opt_out_flag');
    }

    set marketingFlag(value: string | null) {
        if (!value) {
            sessionStorage.removeItem('marketing_opt_out_flag');
        } else {
            sessionStorage.setItem('marketing_opt_out_flag', value);
        }
    }

    get recoverEmail(): string | null {
        return sessionStorage.getItem('session_recover_email');
    }

    set recoverEmail(value: string | null) {
        if (!value) {
            sessionStorage.removeItem('session_recover_email');
        } else {
            sessionStorage.setItem('session_recover_email', value);
        }
    }

    get userEmail(): string | null {
        return localStorage.getItem('user_email');
    }

    set userEmail(value: string | null) {
        if (!value) {
            localStorage.removeItem('user_email');
        } else {
            localStorage.setItem('user_email', value);
        }
    }

    /**
     * Indicates if the user is already logged in.
     */
    async isLoggedIn(): Promise<boolean> {
        const cookies = cookie.parse(document.cookie);
        const accessToken = cookies.accessToken;
        const refreshToken = cookies.refreshToken;

        if (!accessToken) {
            return false;
        }

        const tokenData = parseJwt(accessToken);
        const currentTime = Math.floor(Date.now() / 1000);

        if (tokenData && tokenData.exp > currentTime) {
            return true;
        }

        if (!refreshToken) {
            return false;
        }

        const newTokens = await this.refreshAndStoreTokens(refreshToken);
        if (!newTokens) {
            return false;
        }

        return true;
    }

    /**
     * Indicates if the user is already logged in.
     */
    async refreshAndStoreTokens(refreshToken: string): Promise<any> {
        try {
            const newTokens = await store.dispatch(
                api.endpoints.refreshTokens.initiate({
                    refreshToken,
                }),
            );

            if ('error' in newTokens) {
                return false;
            }

            if (newTokens.data) {
                this.setAuthCookies(
                    newTokens.data.idToken,
                    newTokens.data.accessToken,
                    newTokens.data.refreshToken,
                );
                return newTokens.data;
            }
        } catch (e) {
            return null;
        }
    }

    /**
     * Clean up stored data.
     */
    cleanUp(): void {
        this.registeredUser = null;
        this.recoverEmail = null;
        // For disclaimer (top banner) purposes
        // https://selina.atlassian.net/browse/PDP-109
        sessionStorage.removeItem('show_disclaimer');
    }

    /**
     * Executes login request.
     */
    async login(credentials: LoginModel): Promise<LoginResult> {
        this.userEmail = credentials.email;
        const result = await store.dispatch(
            api.endpoints.login.initiate({
                username: credentials.email,
                password: credentials.password,
            }),
        );

        this.cleanUp();

        if ('error' in result) {
            const error = result.error as FetchBaseQueryError;

            return (error.data as { errorMessage?: string }).errorMessage ===
                'User is not confirmed.'
                ? 'needsVerification'
                : 'failed';
        }

        this.setAuthCookies(
            result.data.idToken,
            result.data.accessToken,
            result.data.refreshToken,
        );

        return 'success';
    }

    setAuthCookies = (
        idToken: string,
        accessToken: string,
        refreshToken: string,
    ) => {
        const cookies = [
            { name: 'idToken', value: idToken },
            {
                name: 'accessToken',
                value: accessToken,
            },
            {
                name: 'refreshToken',
                value: refreshToken,
            },
        ];

        cookies.forEach(({ name, value }) => {
            document.cookie = cookie.serialize(name, value, {
                path: '/',
                secure: true,
                httpOnly: false,
                sameSite: 'none',
                domain: window._env_.SELINA_DOMAIN,
            });
        });
    };

    clearAuthCookies = () => {
        ['idToken', 'accessToken', 'refreshToken'].forEach((name) => {
            document.cookie = cookie.serialize(name, '', {
                path: '/',
                secure: true,
                httpOnly: false,
                sameSite: 'none',
                domain: window._env_.SELINA_DOMAIN,
                expires: new Date(0),
            });
        });
    };

    /**
     * Executes logout request.
     */
    async logout(): Promise<void> {
        try {
            this.userEmail = null;
            const cookies = cookie.parse(document.cookie);
            const { accessToken, refreshToken } = cookies;

            if (accessToken && refreshToken) {
                await store.dispatch(
                    api.endpoints.logout.initiate({
                        accessToken,
                        refreshToken,
                    }),
                );
            }

            this.clearAuthCookies();
            this.cleanUp();
            localStorage.clear();
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    }

    /**
     * Sign up a new user.
     */
    async register(value: RegisterModel): Promise<boolean> {
        const result = await store.dispatch(
            api.endpoints.register.initiate({
                username: value.email,
                password: value.password,
            }),
        );

        if ('error' in result) {
            return false;
        }

        return true;
    }

    /**
     * Confirm sign up of a new user.
     */
    async confirmRegistration(
        value: ConfirmRegistrationModel,
    ): Promise<boolean> {
        const result = await store.dispatch(
            api.endpoints.confirmRegistration.initiate({
                username: value.email,
                code: value.code,
            }),
        );

        if ('error' in result) {
            return false;
        }

        return true;
    }

    /**
     * Resend the confirmation code.
     */
    async resendCode(email: string): Promise<boolean> {
        const result = await store.dispatch(
            api.endpoints.resendCode.initiate({
                email,
            }),
        );

        if ('error' in result) {
            return false;
        }

        return true;
    }

    /**
     * Request a password recovery for a user.
     */
    async requestPasswordRecovery(
        value: RecoverPasswordModel,
    ): Promise<boolean> {
        const result = await store.dispatch(
            api.endpoints.requestPasswordRecovery.initiate({
                username: value.email,
            }),
        );

        if ('error' in result) {
            return false;
        }

        return true;
    }

    /**
     * Request a password reset for a user.
     */
    async resetPassword(value: ResetPasswordModel): Promise<boolean> {
        const result = await store.dispatch(
            api.endpoints.resetPassword.initiate({
                username: value.email,
                verificationCode: value.code,
                newPassword: value.password,
            }),
        );

        if ('error' in result) {
            return false;
        }

        return true;
    }
}
